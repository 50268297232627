<template>
  <footer>
    <div class="footerWrapper">
      <div class="footerLinksWrapper">
        <a href="https://hollywoodtramp.de/impressum/" target="_blank">Impressum</a>
        <a href="https://hollywoodtramp.de/datenschutz/" target="_blank">Datenschutz</a>
        <a href="https://hollywoodtramp.de/agb/" target="_blank">AGB</a>
        <a href="https://hollywoodtramp.de/kontakt/" target="_blank">Kooperation</a>
        <a href="https://hollywoodtramp.de/blog/" target="_blank">Zum Blog</a>
        <div class="socialLinkWrapper">
          <a href="https://www.instagram.com/hollywoodtramp/" target="_blank"><img src="/assets/images/icons/instagram.png" alt="Hollywood Tramp Instagram Link"></a>
          <a href="https://www.tiktok.com/@hollywoodtramp" target="_blank"><img src="/assets/images/icons/tiktok.png" alt="Hollywood Tramp TikTok Link"></a>
          <a href="https://www.facebook.com/hollywoodtrampmag" target="_blank"><img src="/assets/images/icons/facebook.png" alt="Hollywood Tramp Facebook Link"></a>
          <a href="https://open.spotify.com/show/5QFU5DJf0wNzXnG35d7N9T" target="_blank"><img src="/assets/images/icons/spotify.png" alt="Hollywood Tramp Spotify Link"></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Footer",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {
  }
}
</script>

<style scoped>
/* Footer */

footer{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.footerWrapper{
  display: flex;
  justify-content: flex-end;
  color: white;
  padding-bottom: 18px;
  font-family: Oswald;
}

.footerLinksWrapper{
  display: flex;
  justify-content: flex-end;
  column-gap: 18px;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 766px){
  .footerLinksWrapper{
    justify-content: center;
    row-gap: 12px;
  }
}

.footerLinksWrapper a{
  color: white;
  text-transform: uppercase;
}

.socialLinkWrapper{
  display: flex;
  column-gap: 18px;
}

.socialLinkWrapper img{
  width: 100%;
  max-width: 30px;
}


</style>