<template>
  <div>
    <div class="content-1000">
      <div class="grid-12" style="column-gap: 12px;">
        <div class="col-sp-12 row-sp-12 eventDetailsWrapper">
<!--          Hier noch den Bilderpfad-->
          <img :src="config.projectURL + 'assets/images/events/'+eventDetails.image+'.jpg'" alt="">
          <div class="eventInfoWrapper grid-12">
            <div class="col-sp-12 row-sp-12">
              <div class="location flex">
                <h6>{{eventDetails.cityName}}</h6>
                <h6>|</h6>
                <h6>{{eventDetails.subTitle}}</h6>
              </div>
              <h2>{{eventDetails.title}}</h2>
            </div>
            <div class="col-sp-12 row-sp-12">
              <div class="grid-12" style="column-gap: 12px;">
                <div class="col-sp-4 row-sp-12 infoDetailBox">
                  <h3>{{ startDate }}</h3>
                  <h6>{{ startDay }}</h6>
                </div>
                <div class="col-sp-4 row-sp-12 infoDetailBox">
                  <h3>{{startTime}}</h3>
                  <h6>Start</h6>
                </div>
                <div class="col-sp-4 row-sp-12 infoDetailBox" v-if="eventDetails.abendkasse == 1">
                  <h3>{{eventDetails.abendkassePrice}}€</h3>
                  <h6>Abendkasse</h6>
                </div>
                <div class="col-sp-4 row-sp-12 infoDetailBox" v-else>
                  <h3>Keine</h3>
                  <h6>Abendkasse</h6>
                </div>
                <div class="col-sp-12 row-sp-12 infoDetailBox">
                  <a target="_blank" :href="'https://www.google.com/maps?q='+eventDetails.location">
                    <div class="flex">
                      <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" fill="#ffffff" class="fill-000000"></path><path d="M0 0h48v48H0z" fill="none"></path></svg>
                      <h6>{{eventDetails.location}}</h6>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 eventDetailsContent">
              <h3>Eventdetails</h3>
              <div class="flex eventBenefits">
                <div class="benefit" v-for="label in eventLabels" :key="label.id">
                  <h6>{{label.title}}</h6>
                </div>
              </div>
              <p v-html="nl2br(eventDetails.description)"></p>
            </div>
          </div>
          <hr>
          <div class="ticketsOverview grid-12">
            <div class="col-sp-12 row-sp-12" v-if="parseInt(eventDetails.ticketSale) === 1">
              <h3>Jetzt Tickets sichern</h3>
              <div class="pad-8"></div>
              <section class="bgcolor-black br-10" id="ticketSale" v-if="eventDetails.ticketSaleLink != null && eventDetails.ticketSaleLink !== 'null'">
                <div class="grid-12">
                  <div class="col-sp-12 col-hp-6 row-sp-12 pad-16 ">
                    <p>Tickets für dieses Event laufen über einen externen Anbieter:</p>
                  </div>
                  <div class="col-sp-12 col-hp-6 row-sp-12 t-right pad-8">
                    <a :href="eventDetails.ticketSaleLink" target="_blank" style="background: #EBDF4B;" class="button color-black">Ticket Shop öffnen</a>
                  </div>
                </div>
              </section>
              <section v-else>
                <div v-if="ticketItems.length == 0">
                  <div class="pad-8"></div>
                  <h2 style="font-size: 1.2em;">Vorverkauf startet demnächst</h2>
                </div>
                <ticket-items v-for="ticket in ticketItems" :ticket-items="ticket" :key="ticket.id" ></ticket-items>
                <div class="soldoutWrapper" v-if="!ticketItems">
                  <h5>Ausverkauft<br><span style="font-weight: 400; font-size: 0.8em; margin-top: -4px; display: block;">Ab jetzt nur noch Abendkasse</span></h5>
                </div>
                <div v-if="futureTicketItems.length > 0">
                  <div class="pad-8"></div>
                  <h2 style="font-size: 1.2em;">Weitere Tickets sind demnächst verfügbar</h2>
                </div>
                <future-ticket-items v-for="ticket in futureTicketItems" :ticket-items="ticket" :key="ticket.id" ></future-ticket-items>
                <div v-if="!ActiveTicketsCheck">
                  <div class="ticketwrapper">
                    <div class="ticketname flex">
                      <h4>Abendkasse</h4>
                    </div>
                    <p>Der Vorverkauf ist beendet. Weitere Tickets erhälst du nur noch an der Abendkasse.</p>
                    <div class="buy flex">
                      <h6>{{eventDetails.abendkasse}}€<span>(inkl. Mwst)</span></h6>
                    </div>
                  </div>
                </div>

              </section>
              <div style="width: 100%;margin-bottom: 30px" class="t-center pad-16" >
                <router-link to="/">
                  <button class="buyButton" style="margin:0 auto;cursor: pointer">Zurück zur Übersicht</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="modal" id="filterModal">
      <div class="vueModal" v-if="filterModal">
        <div class="vueModalOuter" @click="filterModal = false;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>Events Filtern</h2>
            <button @click="filterModal = false;" class="button fluid bgcolor-blue color-white">
              Okay
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketItems from "@/components/eventDetails/ticket";
import futureTicketItems from "@/components/eventDetails/futureticket";
import {aSyncData, nl2br} from "@/modules/main";
import router from "@/router";

export default {
  name: "EventDetails",
  title: "EventDetails",
  components: {
    // Lounge,
    // 'events': events,
    // eventLabels,
    ticketItems,
    futureTicketItems
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      events: [],
      eventDetails: {},
      eventLabels: '',
      startTime: '',
      startDate: '',
      startDay: '',
      isAttendees: false,
      ticketItems: [],
      futureTicketItems: [],
      activeTicketItems: [],
      lounges: '',
      filterModal: false,
      cheapestPrice: '',
      vvkEnd: '',
      ActiveTicketsCheck: false,
    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
    this.loadDetails()
    //this.getCheapestTicket()
  },
  methods: {
    router() {
      return router
    },
    loadDetails(){
      aSyncData('eventdetails/getDetails', {eventId: this.$route.params.id})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.events = r.data.events
              this.eventDetails = r.data.eventDetails
              this.ticketItems = r.data.ticketItems
              this.activeTicketItems = r.data.activeTicketItems
              this.futureTicketItems = r.data.futureTicketItems
              this.eventLabels = r.data.eventLabels
              this.lounges = r.data.lounges
              this.isAttendees = r.data.isAttendees
              const jsTimestamp = r.data.eventDetails.startTime.split(' ')
              const jsDate = jsTimestamp[0].split('-')
              const jsTime = jsTimestamp[1].split(':')
              this.startTime = jsTime[0] + ':' + jsTime[1]
              this.startDate = jsDate[2] + '.' + jsDate[1]
              const d = new Date(r.data.eventDetails.startTime);
              switch(d.getDay()) {
                case 0:
                  this.startDay = 'Sonntag'
                  break;
                case 1:
                  this.startDay = 'Montag'
                  break;
                case 2:
                  this.startDay = 'Dienstag'
                  break;
                case 3:
                  this.startDay = 'Mittwoch'
                  break;
                case 4:
                  this.startDay = 'Donnerstag'
                  break;
                case 5:
                  this.startDay = 'Freitag'
                  break;
                case 6:
                  this.startDay = 'Samstag'
                  break;
              }
              this.checkActiveTickets()
            }
          })
    },
    checkActiveTickets(){

      let obj = this.activeTicketItems;
      for (let key in obj) {
        if (obj[key].available && obj[key].available > 0) {
          this.ActiveTicketsCheck = true
          console.log(obj[key])
        }
      }
    },
    attendee() {
      if(!this.auth.isAuth) this.$router.push('/login?redirect=' + this.$route.path)
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      fD.append('isAttendees', JSON.stringify(this.isAttendees))
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('eventdetails/attendee', fD)
          .then(r => {
            if(r.status === 250) _this.isAttendees = !_this.isAttendees
          })
    },
    nl2br(str){
      return nl2br(str)
    },
    scrollToTickets(){
      this.$refs['tickets'].scrollIntoView({behavior: "smooth"})
    },
    /*
    getCheapestTicket(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('eventdetails/getCheapestTicket', fD)
          .then(r => {
            if(r.status === 250)
              _this.cheapestPrice = r.data.price
              const jsTimestamp = r.data.sellEnd.split(' ')
              const jsTime = jsTimestamp[1].split(':')
              _this.vvkEnd = jsTime[0] + ':' + jsTime[1]
          })
    }
    */

  }
}
</script>

<style scoped>

h1,h2,h3,h4,h5,h6,p,a{
  margin: 0;
}
.content-1000{
  max-width: 800px;
  margin: 100px auto;
  padding-bottom: 120px;
  font-family: Oswald;
}

.flex{
  display: flex;
}

.eventDetailsWrapper{
  background-color: #1D1D1D;
  border-radius: 24px;
}

.eventDetailsWrapper img{
  width: 100%;
  border-radius: 24px 24px 0 0;
}

.eventInfoWrapper{
  padding: 12px 24px 0;
}

.eventInfoWrapper .location{
  column-gap: 6px;
  justify-content: center;
}

.eventInfoWrapper .location h6 {
  font-size: 1em;
  color: #EBDF4B;
  font-weight: 400;
}

.eventInfoWrapper h2{
  color: white;
  text-align: center;
  font-size: 1.8em;
  padding-bottom: 18px;
}

.eventInfoWrapper .infoDetailBox{
  background-color: #272727;
  border-radius: 12px;
  text-align: center;
  padding: 12px 6px;
  margin-bottom: 12px;
}

.infoDetailBox h3{
  color: #EBDF4B;
}

.infoDetailBox h6{
  color: white;
}

.infoDetailBox .flex{
  align-items: center;
  column-gap: 6px;
  justify-content: center;
}

.infoDetailBox .flex h6{
  font-weight: 400;
  font-size: 0.8em;
}

.infoDetailBox svg{
  width: 24px;
}

.eventDetailsContent{
  margin-top: 24px;
}

.eventDetailsContent h3{
  font-size: 1.6em;
  color: #EBDF4B;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 12px;
}

.eventDetailsContent .eventBenefits{
  flex-wrap: wrap;
  gap: 6px;
}

.eventBenefits .benefit{
  background-color: #000000;
  border-radius: 200px;
  text-align: center;
  padding: 6px 12px;

}

.eventBenefits .benefit h6{
  color: white;
  font-weight: 400;
  font-size: 0.8em;
}

.eventDetailsContent p{
  color: white;
  font-size: 1.2em;
  font-weight: 300;
  padding-top: 20px;
  line-height: 1.4;
}

hr{
  opacity: 0.1;
  margin: 32px 24px
}

.ticketsOverview{
  padding: 12px 24px;
}

.ticketsOverview h3{
  font-size: 1.6em;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.ticketwrapper{
  background-color: #272727;
  padding: 16px 20px;
  border-radius: 12px;
  margin: 24px 0;
}

.ticketwrapper .ticketname{
  column-gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}


.ticketwrapper .ticketname h4{
  color: #EBDF4B;
  text-transform: uppercase;
  font-size: 1.3em;
}

.ticketwrapper .almostsoldOut{
  background-color: #B63831;
  color: white;
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 0.7em;
}

.ticketwrapper p{
  color: white;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
}

.ticketwrapper .buy{
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.ticketwrapper .buy h6{
  font-size: 1.4em;
  color: white;
}

.ticketwrapper .buy span{
  font-size: 0.6em;
  font-weight: 400;
  padding-left: 8px;
}

.ticketwrapper .buy button  {
  background-color: #EBDF4B;
  font-family: Oswald;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 1em;
  width: 130px;
  padding: 6px 6px;

}
.buyButton{
  background-color: #EBDF4B;
  font-family: Oswald;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 1em;
  width: 200px;
  padding: 6px 6px;
  color: black;
}

.soldoutWrapper{
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.soldoutWrapper h5{
  font-size: 1.5em;
}

@media (min-width: 767px){

  .eventInfoWrapper{
    padding: 20px 40px 0;
  }

  .ticketsOverview{
    padding: 0 40px 0;
  }

  hr{
    margin: 32px 40px
  }

  .location h6{
    font-size: 1.2em;
  }

  .eventInfoWrapper h2{
    font-size: 2.2em;
    padding-bottom: 30px;
  }

  .infoDetailBox h3 {
    font-size: 1.4em;
  }

  .infoDetailBox h6 {
    font-size: 1em;
  }

  .infoDetailBox svg{
    width: 32px;
  }

  .infoDetailBox .flex h6{
    font-size: 1em;
  }

  .eventDetailsContent{
    margin-top: 30px;
  }

  .eventDetailsContent h3, .ticketsOverview h3{
    font-size: 1.8em;
  }

  .eventDetailsContent .eventBenefits{
    gap: 10px;
  }

  .eventBenefits .benefit{
    padding: 6px 16px;

  }

  .eventBenefits .benefit h6{
    font-size: 1em;
    font-weight: 400;
  }

  .eventDetailsContent p{

  }

  .ticketwrapper .buy{
    margin-top: 20px;
  }

  .ticketwrapper .buy h6{
    font-size: 1.6em;
  }

  .ticketwrapper .almostsoldOut{
    font-size: 0.9em;
  }

  .ticketwrapper .buy button{
    width: 150px;
  }
}




</style>