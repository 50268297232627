<template>
  <article class="ticket">
<!--    <h4 class="color-blue">-->
<!--      {{ ticketItems.ticketTitle }}-->
<!--      <span v-if="ticketItems.available < 1" class="button bgcolor-red color-white tiny" style="padding: 4px 8px; font-size: 0.5em;">Ausverkauft</span>-->
<!--      <span v-else-if="ticketItems.available <= 20" class="button bgcolor-red color-white tiny" style="padding: 4px 8px; font-size: 0.5em;">Fast ausverkauft</span>-->
<!--    </h4>-->
<!--    <p class="ticketDescription" v-html="nl2br(ticketItems.ticketDescription )" />-->
<!--    <div class="grid-12 color-black">-->
<!--      <div class="col-sp-6 row-sp-12">-->
<!--        <h5>{{ ticketItems.price }}€ <span>(inkl. MwSt.)</span></h5>-->
<!--      </div>-->
<!--      <div class="col-sp-6 row-sp-12 t-right" v-if="ticketItems.available > 0">-->
<!--        <button class="bgcolor-blue button color-white" :disabled="loading" :class="{disabled: loading}" @click="checkOutModal = true">-->
<!--          Ticket kaufen-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="ticketwrapper">
      <div class="ticketname flex">
        <h4>{{ ticketItems.ticketTitle }}</h4>
        <span v-if="ticketItems.available < 1" class="almostsoldOut">Ausverkauft</span>
        <span v-else-if="ticketItems.available <= 20" class="almostsoldOut">Fast ausverkauft</span>
        <span v-if="!ticketSellEndCheck(ticketItems.sellEnd)" class="almostsoldOut">Nicht mehr erhältlich</span>
      </div>
      <p>{{ticketItems.ticketDescription}}</p>
      <div class="buy flex">
        <h6>{{ ticketItems.price }}€<span>(inkl. Mwst)</span></h6>
        <button v-if="ticketItems.available > 1 && ticketSellEndCheck(ticketItems.sellEnd)" class="buyButton" :disabled="loading" :class="{disabled: loading}" @click="scrollToTop(); checkOutModal = true;" style="cursor: pointer">
          Jetzt kaufen
        </button>
      </div>

    </div>
  </article>
  <VueModal style="position: fixed;
    z-index: 999;
    top: 0;
    background: #301D44;
    padding: 32px;
    width: 100%;
    left: 0;
    right: 0;
    box-sizing: border-box;
    height: 100%;" v-if="checkOutModal" @close="checkOutModal = false" min-size="small" max-size="large">
    <check-out :item="ticketItems" checkout-type="tickets" @close="checkOutModal = false" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import CheckOut from "@/components/payment/checkOut";
import {nl2br} from "@/modules/main";

export default {
  name: "TicketItems",
  components: {CheckOut},
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      ticketCount: 0,
      loading: false,
      checkOutModal: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    nl2br(str){
      return nl2br(str)
    },
    ticketSellEndCheck(timeString) {
      const currentTime = new Date();
      const inputTime = new Date(timeString);

      if (inputTime < currentTime) {
        return false
      } else {
        return true
      }
    },
    scrollToTop() {
      setTimeout(() => {
        document.documentElement.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.checkOutModal = true;
      }, 0);
    }
  }
}
</script>

<style scoped>

h1,h2,h3,h4,h5,h6,p,a{
  margin: 0;
}
.content-1000{
  max-width: 800px;
  margin: 100px auto;
  padding-bottom: 120px;
  font-family: Oswald;
}

.flex{
  display: flex;
}

.eventDetailsWrapper{
  background-color: #1D1D1D;
  border-radius: 24px;
}

.eventDetailsWrapper img{
  width: 100%;
  border-radius: 24px 24px 0 0;
}

.eventInfoWrapper{
  padding: 12px 24px 0;
}

.eventInfoWrapper .location{
  column-gap: 6px;
  justify-content: center;
}

.eventInfoWrapper .location h6 {
  font-size: 1em;
  color: #EBDF4B;
  font-weight: 400;
}

.eventInfoWrapper h2{
  color: white;
  text-align: center;
  font-size: 1.8em;
  padding-bottom: 18px;
}

.eventInfoWrapper .infoDetailBox{
  background-color: #272727;
  border-radius: 12px;
  text-align: center;
  padding: 12px 6px;
  margin-bottom: 12px;
}

.infoDetailBox h3{
  color: #EBDF4B;
}

.infoDetailBox h6{
  color: white;
}

.infoDetailBox .flex{
  align-items: center;
  column-gap: 6px;
  justify-content: center;
}

.infoDetailBox .flex h6{
  font-weight: 400;
  font-size: 0.8em;
}

.infoDetailBox svg{
  width: 24px;
}

.eventDetailsContent{
  margin-top: 24px;
}

.eventDetailsContent h3{
  font-size: 1.6em;
  color: #EBDF4B;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 12px;
}

.eventDetailsContent .eventBenefits{
  flex-wrap: wrap;
  gap: 6px;
}

.eventBenefits .benefit{
  background-color: #000000;
  border-radius: 200px;
  text-align: center;
  padding: 6px 12px;

}

.eventBenefits .benefit h6{
  color: white;
  font-weight: 400;
  font-size: 0.8em;
}

.eventDetailsContent p{
  color: white;
  font-size: 1.1em;
  font-weight: 300;
  padding-top: 20px;
}

hr{
  opacity: 0.1;
  margin: 32px 24px
}

.ticketsOverview{
  padding: 12px 24px;
}

.ticketsOverview h3{
  font-size: 1.6em;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.ticketwrapper{
  background-color: #272727;
  padding: 16px 20px;
  border-radius: 12px;
  margin: 24px 0;
}

.ticketwrapper .ticketname{
  column-gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}


.ticketwrapper .ticketname h4{
  color: #EBDF4B;
  text-transform: uppercase;
  font-size: 1.3em;
}

.ticketwrapper .almostsoldOut{
  background-color: #B63831;
  color: white;
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 0.7em;
}

.ticketwrapper p{
  color: white;
  font-size: 1.1em;
  font-weight: 300;
}

.ticketwrapper .buy{
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.ticketwrapper .buy h6{
  font-size: 1.4em;
  color: white;
}

.ticketwrapper .buy span{
  font-size: 0.6em;
  font-weight: 400;
  padding-left: 8px;
}

.ticketwrapper .buy button  {
  background-color: #EBDF4B;
  font-family: Oswald;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 1em;
  width: 130px;
  padding: 6px 6px;
}
.buyButton{
  background-color: #EBDF4B;
  font-family: Oswald;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 1em;
  width: 200px;
  padding: 6px 6px;
  color: black;
}

.soldoutWrapper{
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.soldoutWrapper h5{
  font-size: 1.5em;
}

@media (min-width: 767px){

  .eventInfoWrapper{
    padding: 20px 40px 0;
  }

  .ticketsOverview{
    padding: 0 40px 0;
  }

  hr{
    margin: 32px 40px
  }

  .location h6{
    font-size: 1.2em;
  }

  .eventInfoWrapper h2{
    font-size: 2.2em;
    padding-bottom: 30px;
  }

  .infoDetailBox h3 {
    font-size: 1.4em;
  }

  .infoDetailBox h6 {
    font-size: 1em;
  }

  .infoDetailBox svg{
    width: 32px;
  }

  .infoDetailBox .flex h6{
    font-size: 1em;
  }

  .eventDetailsContent{
    margin-top: 30px;
  }

  .eventDetailsContent h3, .ticketsOverview h3{
    font-size: 1.8em;
  }

  .eventDetailsContent .eventBenefits{
    gap: 10px;
  }

  .eventBenefits .benefit{
    padding: 6px 16px;

  }

  .eventBenefits .benefit h6{
    font-size: 1em;
    font-weight: 400;
  }

  .eventDetailsContent p{

  }

  .ticketwrapper .buy{
    margin-top: 20px;
  }

  .ticketwrapper .buy h6{
    font-size: 1.6em;
  }

  .ticketwrapper .almostsoldOut{
    font-size: 0.9em;
  }

  .ticketwrapper .buy button{
    width: 150px;
  }
}




</style>