<template>
  <main class="content-500" id="tickets">
    <section class="t-center color-black" v-if="user.lounges.length">
      <h1 >Meine Lounges</h1>
      <h2>Übersicht der Lounges</h2>
    </section>
    <section class="pad-16" id="newTickets" v-if="user.lounges.length">
      <lounge-single v-for="lounge in user.lounges" :lounge="lounge" :key="lounge.id" />
    </section>
    <section class="t-center color-black">
      <h1>Meine Tickets</h1>
      <h2>Übersicht der Tickets</h2>
    </section>
    <section class="pad-16" id="newTickets">
      <ticketSingle v-for="ntitems in newTickets" :ticket="ntitems" :key="ntitems.id" />
      <p class="t-center color-black" v-if="newTickets.length == 0">
        Du hast aktuell keine Tickets
      </p>
    </section>
    <section class="pad-16" id="oldTickets" v-if="oldTickets !== ''">
      <div class="t-center color-black">
        <h3>Alte Tickets</h3>
      </div>
      <ticketSingle v-for="otitems in oldTickets" :ticket="otitems" :key="otitems.id" />
      <p class="t-center color-black" v-if="oldTickets.length == 0">
        Du hast keine alten Tickets
      </p>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketSingle from "@/components/tickets/ticketSingle";
import {stringToDate} from "@/modules/main";
import LoungeSingle from "@/components/tickets/loungeSingle";

export default {
  name: "Tickets",
  title: "Tickets",
  components: {
    LoungeSingle,
    ticketSingle
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      tickets: [],
    }
  },
  computed: {
    newTickets() {
      return this.tickets.filter(e => {
        try {
          return parseInt(e.status) === 0 && stringToDate(e.startTime).getTime() >= (new Date()).getTime()
        }catch (e) {
          return false
        }
      })
    },
    oldTickets(){
      return this.tickets.filter(e => {
        try {
          return parseInt(e.status) !== 0 || stringToDate(e.startTime).getTime() < (new Date()).getTime()
        }catch (e) {
          return false
        }
      })
    }
  },
  mounted() {
    this.config.loading = false
    this.getTickets()
  },
  methods: {
    getTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('tickets/getTickets', fD)
          .then((response) => {
            if(response.status === 250){
              _this.tickets = response.data
            }
          })
    }
  }
}
</script>

<style>
main#tickets{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#tickets h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#tickets h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(0,0,0,0.8);
  margin-bottom: -16px;
}
main#tickets h3{
  font-size: 1.2em;
  font-weight: 200;
  color: rgba(0,0,0,0.8);
  margin-bottom: -16px;
}
main #newTickets{
  margin-top: 32px;
  padding: 32px 16px;
}
main #oldTickets h3{
  margin-bottom: 32px;
}
</style>