<template>
  <div style="margin-top: 30px; overflow: auto">
    <h2 class="f-bold color-black" style="font-size: 1.4em;">
      Guthaben aufladen
    </h2>
    <h3 class="color-blue">Betrag auswählen</h3>
    <div class="field flex">
      <input id="chargeInput" type="number" step="0.1" v-model="value">
      <span>€</span>
    </div>
    <div class="grid-12 t-center selectionButtons">
      <div class="col-sp-4 row-sp-12 pad-4">
        <button @click="value = 10.00" class="button color-white" :class="{active: value === 10.00}">
          10,00€
        </button>
      </div>
      <div class="col-sp-4 row-sp-12 pad-4">
        <button @click="value = 25.00" class="button color-white" :class="{active: value === 25.00}">
          25,00€
        </button>
      </div>
      <div class="col-sp-4 row-sp-12 pad-4">
        <button @click="value = 50.00" class="button color-white" :class="{active: value === 50.00}">
          50,00€
        </button>
      </div>
    </div>
    <p style="font-size: 0.8em;" class="pad-8">
      Mit dem klicken auf "Kaufen" bestätige ich, dass ich die AGB gelesen habe und diese akzeptiere.
    </p>
    <div>
      <p class="error" v-if="error">
        {{ error }}
      </p>
      <button class="button bgcolor-blue color-white fluid" type="button" @click="checkOut" :disabled="!value" :class="{disabled: !value}">
        Aufladen
      </button>
    </div>
  </div>
  <loader size="small" v-if="loading" />
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import Coupon from "@/components/payment/coupon";
import PaymentType from "@/components/payment/paymentType";

export default {
  name: "credits",
  components: {Loader},
  props: {
    userInfo: {
      type: Object,
      required: false
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      loading: false,
      value: 10,
      error: ''
    }
  },
  mounted() {
  },
  methods: {
    checkOut(){
      if(!this.value) return
      this.loading = true
      this.error = ''
      aSyncData('checkout/credits', {credits: this.value * 100})
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
              }else if(r.data.cipher){
                this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
              }
            }else if(r.status === 295) {
              this.auth.update()
                  .catch(e => {})
              this.error = 'Bitte erneut einlogen oder Email angeben'
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
  .smallHint{
    font-size: 0.8em;
  }
  .disabled,
  .disabled:hover{
    background-color: gray !important;
  }
  button.button.active{
    background-color: #2068ff !important;
  }

  .button-primary{
    width: 80%;
    margin-top: 8px;
  }

  .flex{
    display: flex;
  }

  .field {
    margin: 12px 0;
    justify-content: center;
    column-gap: 6px;
    align-items: center;
  }

  .field input{
    padding: 8px;
    width: 14%;
    max-width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #1E1E1E;
    color: white;
  }

  .button{
    color: #1E1E1E;
  }

</style>