import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from "./router";
import title from '@/mixins/title'
import { createPinia } from 'pinia'

const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = Vue.markRaw(router)
});

const vueProto = Vue.createApp(App)
    .use(pinia)
    .use(VueAxios, axios)
    .use(router)
    .mixin(title)
vueProto.config.warnHandler = () => null;
vueProto.mount('#App')