<template>
  <main>
    <section class="login" v-if="page === 'login'">
      <div class="content-500">
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="email" class="color-black" name="email" placeholder="E-Mail Adresse" required v-model="email">
          </div>
          <div class="field simple pad-8">
            <input type="password" class="color-black" name="password" placeholder="Passwort" required v-model="password">
            <div class="grid-12 loginmenu">
              <div class="col-sp-6 row-sp-12 t-left">
                <div class="rememberme field simple color-black" v-if="!config.cordova">
                  <input type="checkbox" id="logincookie" name="logincookie" v-model="cookie">
                  <label for="logincookie">Angemeldet bleiben</label>
                </div>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <div class="forgotpassword field simple color-black">
                  <a @click="page = 'forgot'">Passwort vergessen?</a>
                </div>
              </div>
            </div>
            <p class="feedbackmessage t-center pad-8 color-blue" v-if="loginStatus === 'fail'">
              Login fehlgeschlagen. Bitte überprüfe deine Angaben.
            </p>
            <p class="feedbackmessage t-center pad-8 color-blue" v-else-if="loginStatus !== 'ok' && loginStatus !== ''">
              Login fehlgeschlagen. Bitte überprüfe deine Angaben.
            </p>
          </div>
        </div>
        <div class="t-center pad-16">
          <button id="LoginButton" @click="login" class="button bgcolor-black color-white circular">
            Anmelden
          </button>
          <div class="divider color-black">
            oder
          </div>
          <router-link id="RegisterButton" to="register" class="button bgcolor-blue color-white circular">
            Kostenlos Registrieren
          </router-link>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'forgot'">
      <div class="content-500">
        <div class="t-center pad-16">
          <h1 class="f-bold color-black">
            Passwort vergessen?
          </h1>
          <h2 class="color-blue f-normal">
            Bitte gib deine E-Mail-Adresse ein
          </h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input class="color-black" type="email" name="email" placeholder="E-Mail-Adresse" required v-model="email">
          </div>
        </div>
        <p class="feedbackmessage t-center pad-8 color-blue" v-if="forgotStatus === 'ok'">
          Sofern dein Account mit dieser E-Mail verbunden ist, erhälst du eine E-Mail.
        </p>
        <div class="t-center pad-16 width-300">
          <button @click="forgotPassword" class="button fluid bgcolor-blue color-white circular" :disabled="resetLoading" :class="{disabled: resetLoading}">
            Passwort zurücksetzen
          </button>
          <div class="divider color-black">
            oder
          </div>
          <button type="button" class="button fluid bgcolor-black color-white circular" @click="page = 'login'">
            Zurück
          </button>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'resetpassword'">
      <div class="content-500">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Neues Passwort erstellen
          </h1>
          <h2 class="color-blue">
            Bitte gib dein neues Passwort ein:
          </h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="password" class="color-white" name="password" placeholder="Passwort" required v-model="pw1">
          </div>
          <div class="field simple pad-8">
            <input type="password" class="color-white" name="password2" placeholder="Passwort wiederholen" required v-model="pw2">
          </div>
          <p class="feedbackmessage t-center pad-8 color-petrol" v-if="resetStatus === 'ok'">
            Dein Passwort wurde erfolgreich geändert.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="!passwordCheck && pw2.length > 0">
            Die Passwörter stimmen nicht überein oder sind nicht mindestens 8 Zeichen lang.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="resetStatus === 'invalid'">
            Der Link ist abgelaufen. Bitte fordere einen neuen an.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="resetStatus === 'password'">
            Ups, da lief was schief.
          </p>
        </div>
        <div class="t-center pad-16" v-if="resetStatus !== 'ok'">
          <button @click="resetPassword" class="button bgcolor-blue color-white circular" :disabled="!passwordCheck">
            Passwort speichern
          </button>
          <div class="divider">
            oder
          </div>
          <button type="button" class="button bgcolor-second color-white circular" @click="page = 'login'">
            Zurück
          </button>
        </div>
        <div class="t-center pad-16" v-else>
          <button @click="page = 'login'" class="button color-white circular">
            Zum Login
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Login",
  title: "Login",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      page: this.$route.query.page ?? 'login',
      token: this.$route.query.resetkey,
      cookie: this.config.cordova,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      loginStatus: '',
      forgotStatus: '',
      resetStatus: '',
      resetLoading: false
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  watch: {
    email(){
      this.resetLoading = false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    login(){
      const _this = this
      this.auth.login(this.email, this.password, this.cookie)
          .then((response) => {
            if(response === 250){
              _this.loginStatus = 'ok'
              if(_this.$route.query.redirect){
                _this.$router.push(_this.$route.query.redirect)
              }else{
                _this.$router.push('Home')
              }
            }else if(response === 251){
              _this.loginStatus = 'user'
            }else if(response === 252){
              _this.loginStatus = 'password'
            }
          })
          .catch(() => {
            _this.loginStatus = 'fail'
          })
    },
    //reset password
    forgotPassword() {
      this.resetLoading = true
      const _this = this
      this.auth.resetPassword(this.email)
          .then((response) => {
            if(response === 250){
              _this.forgotStatus = 'ok'
            }else if(response === 251){
              _this.forgotStatus = 'email'
            }else if(response === 252){
              _this.forgotStatus = 'fail'
            }
          })
          .catch(() => {
            _this.forgotStatus = 'fail'
            _this.resetLoading = false
          })
    },
    //restore password
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then(r => {
            if(r === 250){
              _this.resetStatus = 'ok'
            }else if(r === 251){
              _this.resetStatus = 'invalid'
            }else if(r === 252){
              _this.resetStatus = 'password'
            }
          })
          .catch(() => {
            _this.resetStatus = 'password'
          })
    }
  }
}
</script>

<style scoped>
.login{
  padding-top: 20vh;
}
.login .field.simple input{
  border-radius: 8px;
  border: none;
  background-color: var(--second-bg-color);
}
.loginmenu{
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding-top: 8px;
}
.forgotpassword a{
  font-size: 0.7em;
  text-decoration: underline;
  cursor: pointer;
}
.rememberme span{
  font-size: 0.8em;
}
.divider{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
  text-align: center;
  padding: 8px 0;
}
.divider:before,
.divider:after{
  content: ' ---------- ';
  color: rgba(0,0,0,0.4);
}
#LoginButton{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
}
#RegisterButton{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
}
.feedbackmessage{
  font-size: 0.8em;
}
.width-300{
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.field.simple label{
  font-size: 0.7em;
  font-weight: normal;
  display: inline;
  vertical-align: 2px;
  padding-left: 4px;
}
</style>