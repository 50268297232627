<template>
  <header>
    <div class="headerWrapper">
      <img src="/assets/images/cropped-cropped-cropped-HT-LOGO-2020.webp" alt="Hollywood Tramp Logo">
      <div class="headerLinksWrapper">
        <a href="https://hollywoodtramp.de">
          <button class="backToWebsiteButton">Zurück zur Website</button>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: "NavBar",
  components: {},
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },

}

</script>

<style scoped>
/* Header */
header{
  width: 100%;
  max-width: 1200px;
  margin: 32px auto 0;
  box-sizing: border-box;
  font-family: 'Oswald';

}

.headerWrapper{
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 7px );
  border-radius: 10px;
  padding: 16px 32px;
  align-items: center;
}

@media (max-width: 766px){
  .headerWrapper{
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 100%;
  }
  .backToWebsiteButton{
    margin-top: 20px;
  }

}

.headerWrapper img{
  width: 100%;
  max-width: 250px;
}

.backToWebsiteButton{
  color: #ffffff!important;
  font-size: 0.8em;
  font-weight: bold;
  background: rgba( 255, 255, 255, 0.1 );
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 28px;
  border: #ffffff 2px solid;
  padding: 12px 24px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>