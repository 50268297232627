<template>
  <div class="col-sp-12 col-tb-6 row-sp-12 pad-16">
    <article class="event" @click="$router.push({name: 'EventDetails', params: { id: event.id }});">
    <div class="grid-12" style="column-gap: 12px;">
      <div class="col-sp-12 row-sp-12 eventWrapper">
        <img :src="config.projectURL + 'assets/images/events/'+event.image+'.jpg'" alt="">
        <div class="eventInfoWrapper grid-12">
          <div class="col-sp-2 row-sp-12 eventDate">
            <h5>{{getMonthFromDate(event.startTime)}}</h5>
            <h6>{{getDayFromDate(event.startTime)}}</h6>
          </div>
          <div class="col-sp-10 row-sp-12 eventName">
            <h2>{{event.title}}</h2>
            <div class="location flex">
              <h6>{{event.cityName}}</h6>
              <h6>|</h6>
              <h6>{{event.subTitle}}</h6>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 buttonClass">
            <button class="buyTicket">Ticket sichern</button>
          </div>
        </div>
      </div>
    </div>
  </article>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Event",
  props: ["event"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    getDayFromDate(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2];
    },
    getMonthFromDate(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      if (jsDate[1] == '01'){return 'Januar'}
      if (jsDate[1] == '02'){return 'Februar'}
      if (jsDate[1] == '03'){return 'März'}
      if (jsDate[1] == '04'){return 'April'}
      if (jsDate[1] == '05'){return 'Mai'}
      if (jsDate[1] == '06'){return 'Juni'}
      if (jsDate[1] == '07'){return 'Juli'}
      if (jsDate[1] == '08'){return 'August'}
      if (jsDate[1] == '09'){return 'September'}
      if (jsDate[1] == '10'){return 'Oktober'}
      if (jsDate[1] == '11'){return 'November'}
      if (jsDate[1] == '12'){return 'Dezember'}
    },
  }
}
</script>

<style scoped>
.flex{
  display: flex;
}

.eventWrapper{
  background-color: #1D1D1D;
  border-radius: 24px;
  font-family: Oswald;
}

.eventWrapper img{
  width: 100%;
  border-radius: 24px 24px 0 0;
}

.eventWrapper .eventInfoWrapper {
  padding: 8px 10px;
}

.eventInfoWrapper .eventDate{
  background-color: #EBDF4B;
  padding: 24px 0;
  border-radius: 10px;
  text-align: center;
}

.eventInfoWrapper .eventDate h5{
  color: #1D1D1D;
  font-size: 1.1em;
  font-weight: 500;
}

.eventInfoWrapper .eventDate h6{
  color: #3f3c1a;
  font-size: 1.3em;
}

.eventName{
  /*margin-left: 12px;*/
  margin: auto 12px;
}

.eventName h2{
  color: white;
  font-weight: 500;
  font-size: 1.8em;
  text-align: start;
  padding-bottom: 4px;
}

.eventName .location{
  column-gap: 6px;
}

.eventName .location h6 {
  font-size: 1.1em;
  color: #EBDF4B;
  font-weight: 400;
}

.buttonClass{
  text-align: center;
  margin: 18px 0 20px;
}

.buyTicket{
  width: 90%;
  background-color: white;
  color: #ffffff!important;
  font-size: 0.8em;
  font-weight: bold;
  background-color: rgba( 255, 255, 255, 0.1 )!important;
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 28px;
  border: #ffffff 1.5px solid;
  padding: 12px 32px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>